@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
  background-color: white;
}

.loader {
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid #4fba6d;

  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.animate-slide-in {
  animation: slide-in 0.3s ease-in-out forwards;
}

.animate-slide-out {
  animation: slide-out 0.3s ease-in-out forwards;
}

@keyframes modalFadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}
.modal-open {
  overflow: hidden !important;
}

.mobile-menu-open {
  overflow: hidden !important;
}

.modal-enter {
  animation: modalFadeIn 0.3s forwards;
}

.modal-exit {
  animation: modalFadeOut 0.3s forwards;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@layer base {
  * {
    @apply box-border;
  }
  *::before,
  *::after {
    @apply box-border;
  }

  .anchor-arrow {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 10px;
    overflow: hidden;
  }

  .anchor-arrow::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0e121b;
    transform: rotate(45deg);
    box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
  }
}

.quill > * {
  border-color: inherit !important;
  color: inherit !important;
}

.quill > .ql-toolbar {
  /* border radius of the toolbar */
  border-radius: 10px 10px 0 0;
  border-color: #d4d4d4 !important;
}

.quill > .ql-container {
  /* border radius of the container and for font size*/
  min-height: 150px;
  border-color: #d4d4d4 !important;
  font-size: inherit;
  border-radius: 0 0 10px 10px;
}

.ql-toolbar.ql-snow .ql-picker-label {
  color: inherit !important;
  opacity: 0.76;
}

.ql-snow .ql-picker {
  color: inherit !important;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  /* for placeholder */
  color: inherit;
}

.ql-snow.ql-toolbar button svg {
  opacity: 0.76;
  color: currentColor;
}

.ql-snow .ql-stroke {
  /* for the border of the editor */
  stroke: currentColor !important;
}

.ql-snow .ql-fill {
  /* for the bg color */
  fill: currentColor !important;
}

.ql-picker-item {
  /* for dropdown */
  color: #000 !important;
}

.quill .ql-toolbar :focus {
  border: 1px solid pink !important;
}

.quill.ql-error {
  .ql-toolbar {
    border: 1px solid red !important;
  }

  .ql-container {
    border-left: 1px solid red !important;
    border-right: 1px solid red !important;
    border-bottom: 1px solid red !important;
  }
}

.test {
  background-color: red !important;
  border: pink;
  z-index: 1000;
}

.test :focus {
  background-color: green !important;
}

.quill:focus-within .ql-toolbar,
.quill:focus-within .ql-container {
  border: 1.5px solid #000000 !important;
  outline: none;
  border-radius: 10px;
  /* Add rounded corners */
  /* Optional: subtle shadow for focus */
}

.quill:focus-within {
  border: 1.5px solid #000000 !important;
  border-radius: 10px;
  /* Ensure container also has rounded corners */
  /* Optional */
}
